<template>
  <div class="login">
    <div class="info-box">
      <div class="info-title">营口市智慧教研平台</div>
      <div class="info-nav">
        <div
          :class="['item', current == index ? 'active' : '']"
          v-for="(item, index) in navList"
          :key="index"
          @click="changCurrent(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="info-base">
        <div class="base-box">
          <div class="base-box-top">
            <div class="base-icon-box">
              <img class="base-icon" src="@/assets/user/ic_1.png" alt="" />
            </div>
            <el-input
              v-model="dataForm.phone"
              placeholder="请输入手机号码"
              @blur="checkForm('phone')"
              :style="{
                border: checkList.phone.checked ? '1px solid red' : '',
                'border-radius': checkList.phone.checked ? '3px' : '',
              }"
              type="number"
              @keyup.enter.native="onLogin"
              :clearable="true"
            ></el-input>
          </div>
          <div class="base-box-error" v-if="checkList.phone.checked">
            {{ checkList.phone.msg }}
          </div>
        </div>
        <div class="base-box" v-if="current == 1">
          <div class="base-box-top">
            <div class="base-icon-box">
              <img class="base-icon" src="@/assets/user/ic_3.png" alt="" />
            </div>
            <el-input
              v-model="dataForm.password"
              placeholder="请输入密码"
              @blur="checkForm('password')"
              :style="{
                border: checkList.password.checked ? '1px solid red' : '',
                'border-radius': checkList.password.checked ? '3px' : '',
              }"
              type="password"
              @keyup.enter.native="onLogin"
              :clearable="true"
            ></el-input>
          </div>
          <div class="base-box-error" v-if="checkList.password.checked">
            {{ checkList.password.msg }}
          </div>
        </div>
        <div class="base-box" v-else>
          <div class="base-box-top">
            <div class="base-icon-box">
              <img class="base-icon" src="@/assets/user/ic_2.png" alt="" />
            </div>
            <el-input
              v-model="dataForm.code"
              placeholder="请输入验证码"
              :clearable="true"
              @keyup.enter.native="onLogin"
            ></el-input>
            <div class="base-btn" @click="getCode(3)">{{ codeName }}</div>
          </div>
        </div>
        <div class="base-agreement">
          <img
            class="agreement-icon"
            v-if="isAgreement"
            src="@/assets/user/ic_5.png"
            alt=""
            @click="checkedAgreement"
          />
          <img
            class="agreement-icon"
            v-else
            src="@/assets/user/ic_4.png"
            @click="checkedAgreement"
            alt=""
          />
          <span>请阅读并同意</span>
          <span class="agreement" @click="openXY">《平台会员协议》</span>
        </div>
        <div class="info-base-btn" @click="onLogin" v-loading="loading">
          确认登录
        </div>
        <div class="info-base-operation">
          <div class="register">
            <span>还没有账号?</span>
            <span class="register-btn" @click="goRegister">去注册</span>
          </div>
          <div class="reset" @click="goReset">忘记密码？</div>
        </div>
      </div>
    </div>
    <el-dialog
      title="会员协议"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="1000px"
    >
      <div class="xy-box" v-html="businessInfo.agreement_user"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Api from "@/api";
import { isPhone } from "/utils/formRule.js";
import login from "@/mixins/login.js";
import { mapState } from "vuex";
export default {
  mixins: [login],
  data() {
    return {
      navList: ["验证码登录", "密码登录"],
      current: 0,
      dataForm: {
        phone: "",
        password: "",
        code: "",
      },
      dialogVisible: false,
      rules: {
        phone: { require: true, validator: isPhone.bind({ name: "手机号码" }) },
        password: { require: true, message: "密码不能为空" },
      },
    };
  },
  computed: {
    ...mapState(["businessInfo"]),
  },
  created() {
    this.initForm();
  },
  methods: {
    changCurrent(idx) {
      this.current = idx;
      this.dataForm = {
        phone: "",
        password: "",
        code: "",
        newCode: "",
      };
      this.checkList = {};
      clearTimeout(this.timer);
      this.codeName = "发送验证码";
      this.initForm();
    },
    goRegister() {
      this.$router.replace({
        name: "register",
      });
    },
    openXY() {
      this.dialogVisible = true;
    },
    formCheck() {
      let bool = false;
      let key = this.current == 0 ? ["phone", "code"] : ["phone", "password"];
      key.forEach((item) => {
        if (this.checkList[item] && this.checkList[item].checked) {
          bool = true;
        } else if (!this.dataForm[item] || !this.dataForm[item].trim()) {
          bool = true;
        }
      });
      return bool;
    },
    onLogin() {
      if (this.formCheck()) {
        return this.$message.error(
          this.current == 1
            ? "请输入手机号码和登录密码"
            : "请输入手机号码和验证码"
        );
      } else if (this.isAgreement) {
        return this.$message.error("请阅读会员协议");
      }
      this.loading = true;
      Api.Login({
        type: this.current == 1 ? 1 : 2,
        mobile: this.dataForm.phone,
        password: this.dataForm.password,
        smscode: this.dataForm.code,
      }).then((res) => {
        if (res.status == 1) {
          this.$notify({
            message: "登陆成功",
            type: "success",
          });
          if (this.$route.query.url) {
            this.$router.replace({
              name: this.$route.query.url,
              query: {
                id: this.$route.query.id ? this.$route.query.id : "",
              },
            });
          } else {
            this.$router.replace({
              name: "/",
            });
          }
          this.$uStore("userInfo", res.data);
          this.loading = false;
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      });
    },
    goReset() {
      this.$router.replace({
        name: "findpassword",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border: none;
  flex: 1;
  height: 40px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep .el-input__suffix {
  .el-icon-circle-close:before {
    font-size: 18px;
  }
}
::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
.xy-box {
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  line-height: 30px;
}
.error {
  border: 1px solid red;
  border-radius: 3px;
}
.login {
  width: 1200px;
  height: 700px;
  background: url("../../assets/user/banner2.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  .info-box {
    margin-right: 98px;
    padding-top: 104px;
    box-sizing: border-box;
    width: 404px;
    .info-title {
      font-size: 44px;
      font-family: AliHYAiHei;
      font-weight: bold;
      color: #1d2088;
    }
    .info-nav {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 50px 23px 0 18px;
      box-sizing: border-box;
      .item {
        flex: 1;
        font-size: 18px;
        cursor: pointer;
        font-weight: 400;
        color: #111111;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          font-size: 22px;
          color: #1d2088;
          position: relative;
        }
        &.active::after {
          content: "";
          width: 50px;
          height: 2px;
          background: #1d2088;
          border-radius: 2px;
          position: absolute;
          left: 50%;
          bottom: -12px;
          transform: translateX(-50%);
        }
      }
    }
    .info-base {
      padding-top: 26px;
      width: 100%;
      display: flex;
      flex-direction: column;
      .base-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 18px 0;
        border-bottom: 1px solid #ececec;
        .base-box-top {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .base-box-error {
          color: rgb(245, 108, 108);
          font-size: 12px;
          padding: 4px 0 0 30px;
        }
        .base-icon-box {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          .base-icon {
            width: 18px;
            height: 18px;
          }
        }
        .base-btn {
          width: 96px;
          height: 16px;
          padding: 11px;
          font-size: 13px;

          font-weight: 400;
          color: #1d2088;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1.5px solid #1d2088;
          border-radius: 6px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .base-agreement {
        width: 100%;
        font-size: 14px;

        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
        padding-top: 20px;
        .agreement-icon {
          width: 14px;
          height: 14px;
          margin-right: 12px;
          cursor: pointer;
        }
        .agreement {
          font-size: 14px;

          font-weight: 400;
          color: #1d2088;
          cursor: pointer;
        }
      }
      .info-base-btn {
        width: 100%;
        height: 58px;
        background: #1d2088;
        border-radius: 6px;
        font-size: 18px;

        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 54px;
        cursor: pointer;
        letter-spacing: 2px;
      }
      .info-base-operation {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        .register {
          display: flex;
          align-items: center;
          font-size: 15px;

          font-weight: 400;
          color: #999999;
          .register-btn {
            color: #1d2088;
            cursor: pointer;
          }
        }
        .reset {
          font-size: 15px;

          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
